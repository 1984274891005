import React from "react"
import * as AlertBarStyles from "./AlertBar.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

export default function AlertBar() {
    return (
        <div className={AlertBarStyles.wrapper}>
            <div className={AlertBarStyles.content}>
                <p>We have a new telephone number: <span style={{marginLeft: 20}}><FontAwesomeIcon icon={faPhone}/> <a style={{fontWeight: 500}} href="tel:+441604328260">01604 328 260</a></span></p>
            </div>
        </div>
    )
}